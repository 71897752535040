@import "~react-pdf/dist/esm/Page/AnnotationLayer.css";
@import "~react-pdf/dist/esm/Page/TextLayer.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 60px; /* Adjust based on your actual header height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevents scrolling on the body */
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevents scrolling on the container */
}

.editor-container {
  flex-grow: 1; /* Allows the editor container to take up all available space */
  overflow-y: auto; /* Allows scrolling inside the editor container */
}
