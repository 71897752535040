/* Register.css */

.registration-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .registration-form-container {
    display: fixed;
    align-items: center;
    width: 100px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: auto;
  }

  .registration-header-container {
    display: flex;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
    justify-content: center;
    width: 100%;
  }

  .registration-logo {
    width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .registration-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #4285f4;
    color: white;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .registration-button[disabled] {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }

  .input-group {
    width: 100%;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  /* Add media queries to handle different screen sizes */
  @media screen and (min-width: 768px) {
    .registration-form-container {
      width: 50%;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .registration-form-container {
      width: 30%;
    }
  }

  .return-to-login {
    font-size: 14px;
    text-decoration: none;
  }

  .password-match {
    border: 2px solid #4caf50;
  }
  
  .password-invalid,
  .password-mismatch {
    border: 2px solid #f44336;
  }

  .password-info {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
  }
  
  .password-info .material-icons {
    font-size: 18px;
  }
  
  .password-info:hover .password-requirements-tooltip {
    display: block;
  }
  
  .password-label-container {
    display: flex;
    align-items: center;
  }  

  .email-valid {
    border: 2px solid #4caf50;
  }
  
  .email-invalid {
    border: 2px solid #f44336;
  }

  .firstName-invalid,
  .lastName-invalid {
    border: 2px solid #f44336;
  }