.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-form-container {
    display: fixed;
    align-items: center;
    width: 100px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .login-header-container {
    display: flex;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px;
  }
  
  /* Add media queries to handle different screen sizes */
  @media screen and (min-width: 768px) {
    .login-form-container {
      width: 50%;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .login-form-container {
      width: 30%;
    }
  }
  
  .login-logo {
    width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  h1 {
    margin-bottom: 30px;
    position: center;
  }
  
  .input-group {
    width: 100%;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #4285f4;
    color: white;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #2a75c3;
  }
  
  .forgot-password,
  .register {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  
  a {
    color: #4285f4;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }

  