/* ResetPassword.css */
.reset-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
  }
  
  .reset-password h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .reset-password-form {
    width: 400px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password-form .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .reset-password-form label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .reset-password-form input {
    font-size: 14px;
    padding: 10px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }
  
  .reset-password-button {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #4a90e2;
    padding: 10px 0;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .reset-password-button:hover {
    background-color: #3a78c2;
  }
  